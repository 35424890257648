// 路径地址
// 视频 - 图片路径地址
const PATH = {
  // 登录userIp
  userIp: '183.26.23.166',
  // 附件提交
  annex: '/v1/sysconfig/file_save',
  Anotherplace: '42707c76889111ebab30244bfe7a0fff',
  // 设备模板
  equipImport: '/uploads/tool/equipment.xlsx',
  // DTU模板
  DTUImport: '/uploads/tool/dtuassoequ.xlsx',
  // 操作手册
  handManual: '/uploads/tool/Internet_things_os_2_0.docx',
  // 公共API
  handAPI: '/uploads/tool/thirdparty_api.htm',
}

// rabbitMq 路径地址
const MQTT = {
  // mqtt服务地址
  // SERVICE: 'wss://api.dazhihui029.com:7443/ws',
  SERVICE: 'wss://rabbit.cheng-an.top:7443/ws',
  // mqtt连接用户名
  USERNAME: 'dzh',
  // mqtt连接密码
  PASSWORD: 'dzhadmin',
  // 交换机
  EXCHANGE: '/exchange',
  // 外网订阅频道
  // TOPIC: '/TEST_DZH_2_0/'
  // 内网订阅频道
  TOPIC: '/DZH_2_0/'
  // 测试订阅频道
  // TOPIC: '/dzh_test/'
  //渭南
  // TOPIC: '/weinan/'
  //宝鸡
  // TOPIC: '/baoji/'
  //西安
  // TOPIC: '/xian/'
  //咸阳
  // TOPIC: '/xianyang/'
}
export default {
  PATH: PATH,
  MQTT: MQTT
}
